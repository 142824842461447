.resty-social-bar {
    display: flex;
    justify-content: center;
    margin-top: 35px;
}
.resty-social-bar-item {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-size: contain !important;
    margin-left:50px;
} 