.app-en .resty-not-found {
    direction: ltr;
}
  
.app-he .resty-not-found {
    direction: rtl;
}

a.resty-not-found {
    text-decoration: none;
}

.resty-not-found .resty-not-found-image {
    margin-top: 50px;
    width: 50%;
    max-width: 512px;
    position: relative;
}

.app-en .resty-not-found .resty-not-found-image {
    left: 50%;
    transform: translateX(-50%);
}

.app-he .resty-not-found .resty-not-found-image {
    right: 50%;
    transform: translateX(50%);
}

.resty-not-found-title {
    padding: 10px 30px;
    text-align: center;
    color: #133A75;
}