.app-he .App-content .resty_about_page {
    direction: rtl;
    padding: 3%;
}

.app-he .App-content .resty_about_page p {
    font-size: 1.5em;
}

.app-he .App-content .resty_about_page h1 {
    text-align: center;
}

.rps-slide:nth-child(1) .rps-slide-background {
    filter: brightness(50%);
}

.about-mobile .rps-slide:nth-child(2) .rps-slide-background {
    opacity: 0.5;
}

.rps-slide:nth-child(2) .rps-slide-background {
    opacity: 0.6;
}

.rps-slide:nth-child(3) .rps-slide-background {
    filter: brightness(60%);
}

.rps-slide:nth-child(4) .rps-slide-background {
    opacity: 0.5;
}

.App-content .resty_about_page.first_page, .App-content .resty_about_page.third_page {
    color: #ffffff !important;
}

.App-content .resty_about_page.fourth_page {
    padding-left: 50%;
}

.App-content .about-mobile .resty_about_page.fourth_page {
    padding-left: 5%;
}

.App-content .resty_about_page.fifth_page p:last-child {
    text-align: center;
}

.App-content .resty_about_page.first_page a {
    color: #ffffff;
}

.App-content .resty_about_page.fifth_page p:last-child a {
    background: rgba(255,250,250,0.5);
    -webkit-box-shadow: 0px 0px 22px 5px rgba(255,255,255,0.7);
    -moz-box-shadow: 0px 0px 22px 5px rgba(255,255,255,0.7);
    box-shadow: 0px 0px 22px 5px rgba(255,255,255,0.7);
}

.App-content  a.ranking_button{ 
    position: relative;
    font-weight: bold;
    letter-spacing: 0.3rem;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: #ffffff;
    display: inline-block;
    padding: 16px 32px;
    background: #133A75;
    border-radius: 4px;
    cursor: pointer;
    direction: ltr;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 90px;
    height: 25px;
    line-height: 25px;
    top: 50%;
}

@media only screen and (min-width: 601px) and (max-width: 1000px) {
    .app-he .App-content .resty_about_page p {
        font-size: 1.25em;
    }
}

@media only screen and (max-height: 700px) {
    .app-he .App-content .resty_about_page p {
        font-size: 1.25em;
    }
}

@media only screen and (max-height: 600px) {
    .app-he .App-content .resty_about_page p {
        font-size: 1.15em;
    }
}

@media only screen and (max-height: 500px) {
    .app-he .App-content .resty_about_page p {
        font-size: 1.1em;
    }
}

@media only screen and (max-height: 400px) {
    .app-he .App-content .resty_about_page p {
        font-size: 1em;
    }
}

@media only screen and (max-height: 350px) {
    .app-he .App-content .resty_about_page p {
        font-size: 0.8em;
    }
}