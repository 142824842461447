/*
https://www.color-hex.com/color-palette/30415
*/
html, body {
  background: #EAEAEA;
  overflow-x: hidden;
}

body {
  position: relative;
}

.App {
  padding-top: 60px;
}

.app-en {
  font-family: 'Josefin Sans';
}

.app-he {
  font-family: 'Rubik', sans-serif;
}

.App-menu {
  /*background-color: #107dac;*/
  background-color: #F8F8F8;
  /*min-height: 10vh;*/
  height: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  border-color: #EAEAEA;

  -webkit-transition: box-shadow 0.5s ease-in-out, border-color 0.5s ease-in-out opacity 0.5s ease-in-out;
  -moz-transition: box-shadow 0.5s ease-in-out, border-color 0.5s ease-in-out opacity 0.5s ease-in-out;
  transition: box-shadow 0.5s ease-in-out, border-color 0.5s ease-in-out opacity 0.5s ease-in-out;
}

.App-menu-logo .resty-menu-title {
  left: 90px;
}

.resty-menu-title{
  font-family: 'Josefin Sans';
  color: black;
  left: 40px;
  top:  23px;
  font-size: 18px;
  position: absolute;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.App-menu .App-menu-links {
  font-size: 20px;
  position: absolute;
  right: 30px;
  top: -1px;
}

.app-he .App-menu .App-menu-links {
  top: -3px;
}

.App-menu .App-menu-links li {
  display: inline-block;
}

.App-menu .App-menu-links li a {
  display: inline-block;
  text-decoration: none;
  font-weight: normal;
  padding: 0 20px;
  color:#133A75;
  text-transform: uppercase;

  font-style: normal;
  font-weight: bold;
  font-size: 12px;
}

.app-he .App-menu .App-menu-links li a {
  font-size: 14px;
}

.App-menu .App-menu-links li a::after{
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #59C2E4;
  transition: width .3s;
}
 
.App-menu .App-menu-links li a:hover::after{
  width: 100%;
  transition: width .3s;
}

.App-menu-scroll {
  border-bottom: 1px solid ;
  border-color: #F8F8F8;
  /*
  -webkit-box-shadow: 0px 20px 12px -17px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 20px 12px -17px rgba(0,0,0,0.25);
  box-shadow: 0px 20px 12px -17px rgba(0,0,0,0.25);
  */
  opacity: 0.95;
}

.resty-menu-logo {
  top: 50%;
  left: 10px;
  position: absolute;
  background-image: url("./img/logo-mini.png");
  background-size: cover;
  width: 80px;
  height: 80px;

  margin-top: -80px;
  opacity: 0;
  -webkit-transition: opacity 0.75s ease-in-out, margin 0.5s ease-in-out;
  -moz-transition: opacity 0.75s ease-in-out, margin 0.5s ease-in-out;
  transition: opacity 0.75s ease-in-out, margin 0.5s ease-in-out;
}

.App-menu-logo .resty-menu-logo {
  margin-top: -40px;
  opacity: 1;
}

.app-menu-local-heb-eng {
  text-transform: initial !important;
  /*font-family: 'Josefin Sans';*/
}

.App-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #09d3ac;
}

@media only screen and (min-width: 601px) {
  .resty-link-menu-logo .App-menu-mobile-menu-toggle {
    display: none !important;  /*fix for older devices*/
  } 
}

@media only screen and (max-width: 600px) {
  .App {
    overflow-x: hidden;
  }
  
  .App-menu-links-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    min-height: 400px;
  }

  .App-menu-links-hidden {
    left: -65vw !important;
  }

  .App-menu .App-menu-links {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
  }

  .App-menu .App-menu-links .App-menu-links-container { 
    position: absolute;
    text-align: center;
    height: 70vh;
    max-height: 250px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    padding-left: 1px;
  }

  .App-menu .App-menu-links {
    font-size: calc(7px + 2vmin);
    left: 0;
    top: 0 !important;
    font-weight: 300;
    height: 100%;
    position: fixed;
    width: 60vw;
    background-color: #133A75;
    
  }

  .App-menu .App-menu-links li {
  }

  .App-menu .App-menu-links li a {
    color: #59C2E4;
  }

  .App-menu-mobile-menu-toggle {
    position: absolute;
    right: 10px;
    top: 13px;
  }

  .App-menu-mobile-menu-toggle-icon {
    width: 35px;
    height: 35px;
  }

  .resty-link-menu-logo, .App-menu-mobile-menu-toggle {
    display: initial !important;  /*fix for older devices*/
  }

  .resty-link-menu-logo {
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 20px;
    position: absolute;
    top: 40px;
    margin-left: -50px;
    left: 50%;
  }

  .resty-link-menu-logo-text {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 22px;
  }

  .resty-link-menu-logo-leaves {
    width: 100px;
  }

  .App-menu-logo .resty-menu-title {
    left: 90px;
  }
  
  .resty-menu-title{
    left: 20px;
    top: 22px;
  }

  .App-menu .App-menu-links li a {
    font-weight: lighter;
    font-size: 20px;
  }
}