.resty-categories-tab-container {

}

.MuiTabs-scroller { 
  /*height: 80px;*/
}

.MuiTabs-scroller.MuiTabs-scrollable {
  padding-bottom: 20px;
}

.MuiChip-root  .MuiChip-icon {
  color: #133A75 !important;
  position: absolute !important;
  left: 50% !important;
  top: 30px !important;
  margin-left: -5px !important;
  width: 16px;
  height: 16px;
}

.MuiChip-label {
  font-size: 16px;
}

.app-en .MuiChip-label{
  font-family: 'Josefin Sans';
}

.app-he .MuiChip-label{
  font-family: 'Rubik', sans-serif;
  direction: rtl;
}

.MuiSvgIcon-fontSizeSmall {
  font-size: 2rem !important;
}

.MuiTabs-root .MuiChip-root {
  background-color: transparent !important;
  transition: none !important;
}

.resty-categories-tab-container .MuiChip-clickable.MuiChip-outlined:hover, .MuiChip-clickable.MuiChip-outlined:focus, .MuiChip-deletable.MuiChip-outlined:focus {
  background-color: transparent !important;
}

.resty-categories-tab-container .MuiChip-clickable:active {
  box-shadow: none !important;
}

svg + .MuiChip-label {
  color: #133A75 !important;
}

