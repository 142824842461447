.resty-cards-layout-container {
  margin: 15px 0 0;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  box-shadow: 0px 2.76726px 2.21381px rgba(158, 138, 138, 0.02), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 100px 80px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  width: auto;
  display: inline-block;
  padding: 20px;
  left: 50%;
  position: relative;
  transform: translate(-50%, 0);
}

.resty-cards-layout-container:only-child {
  margin-bottom: 50px;
}

.resty-cards-layout {
  direction: rtl;
  /*max-width: 1600px;*/
  
  display: grid;
  grid-gap: 8px;
  /*grid-template-columns: repeat(8, minmax(160px, 1fr));*/
  /*grid-template-rows: repeat(7,320px);*/
  /*grid-auto-rows: minmax(160px, 1fr);*/
  align-content: top;
  justify-content: center;

  grid-template-columns: repeat(8,11vw);
  grid-auto-rows: 11vw;
}

.resty-cards-layout.card-layout-ltr {
  direction: ltr !important;
}

.resty-more-cards {
  position: relative;
  font-weight: bold;
  letter-spacing: 0.3rem;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: #ffffff;
  display: inline-block;
  margin: 30px 0;
  padding: 16px 32px;
  background: #133A75;
  border-radius: 4px;
  cursor: pointer;
  direction: ltr;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  height: 25px;
  line-height: 25px;
}

/*
  Smartphone
*/
@media only screen and (max-width: 600px) {
  .resty-categories-tab-container {
    padding-top: 10px;
  }
  .resty-cards-layout {
    grid-template-columns: repeat(2, 160px);
    grid-auto-rows: initial !important;
  }
}