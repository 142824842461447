.App-list {
  background-color: #F8F8F8;
}

.list-header {
  min-height: 270px;
  /*min-height: 30vh;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;

  font-weight: 300;
  font-size: 30px;
  line-height: 80px;
  /* or 105% */

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  color: #133A75;
}

.app-he .list-header {
  direction: rtl;
}

.list-logo-img {
  height: 30vh;
  opacity: 0.06;
  filter: alpha(opacity=10);
  width: 400px;
  height: 400px;
  top: 0px;
  position: absolute
}

.list-header-title {
  position: absolute;
}

.list-header-title > b {
  color: #59C2E4;
}

.app-he .list-header-title {
  top:110px;
}

.app-en .list-header-title {
  top:160px;
}

.list-header-sub-title {
  position: absolute;
  font-weight: 300;
}

.app-he .list-header-sub-title {
  top:160px;
}

.app-en .list-header-sub-title {
  top:110px;
}

img.overall {
    content: url("./img/filter/winner-inactive.png");
}
.Mui-selected img.overall {
    content: url("./img/filter/winner-active.png");
}

img.social {
    content: url("./img/filter/social-inactive.png");
}
.Mui-selected img.social {
    content: url("./img/filter/social-active.png");
}

img.israel {
    content: url("./img/filter/israel-inactive.png");
}
.Mui-selected img.israel {
    content: url("./img/filter/israel-active.png");
}

img.global {
    content: url("./img/filter/global-inactive.png");
}
.Mui-selected img.global {
    content: url("./img/filter/global-active.png");
}

img.anonymous {
    content: url("./img/filter/anonymous.jpg");
}
.Mui-selected img.anonymous {
    content: url("./img/filter/anonymous.jpg");
}

@media only screen and (min-width: 600px) {
    .MuiTab-root {
      font-size: 3rem !important;
    }
}
  
@media only screen and (max-width: 600px) {

  .list-header-title {
    font-size: 50px;
    line-height: 50px;
    width: 80%;
    left: 10%;
  }

  .resty-categories-tab-container .MuiTabs-root .MuiTabs-scrollButtons .MuiSvgIcon-root {
    font-size: 15px !important;
  }
  
  .app-he .list-header-title {
    top: 90px;
  }

  .app-he .list-header-sub-title {
    top: 170px;
  }

  .app-en .list-header {
    letter-spacing: 5px;
  }

  .app-en .list-header-title {
    top: 150px;
  }

  .app-en .list-header-sub-title {
    top: 80px;
  }

}

@media only screen and (max-width: 400px) {
  .list-header-title {
    font-size: 40px;
  }
}
