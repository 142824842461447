.resty-card {
  display: inline-block;

  color: #000;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.05);
  
  text-decoration: none;
  text-align: center;
  transition: box-shadow .3s ease-out, transform .3s ease-out, opacity .2s ease-out;
  transition-delay: .1s;
  transform: translateZ(0);
  z-index: 10;

  font-size: calc(5px + 2vmin);

  height: 100%;
  width: 100%;

  -webkit-user-select: none;    
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.corona { 
  -webkit-filter: grayscale(1); 
  pointer-events: none;
  cursor: default;
}

.resty-card-small .resty-card {
  background-color: lightblue;
}

.resty-card-layout-1 {
  grid-column: 1 / 5;
  grid-row: 1 / 5;
}

.resty-card-layout-2 {
  grid-column: 5 / 9;
  grid-row: 1 / 3;
}

.resty-card-layout-3 {
  grid-column: 5 / 9;
  grid-row: 3 / 5;
}

.resty-card-layout-2:first-child, .resty-card-layout-3:first-child {
  grid-column: 1 / 5;
  grid-row: 1 / 3;
}

.resty-card-layout-3:nth-child(2) {
  grid-row: 1 / 3;
}

.resty-card-reg {
  grid-row: span 2 / span 2;
  grid-column: span 2 / span 2;
}

.resty-card-small {
  grid-row: span 1 / span 1;
  grid-column: span 2 / span 2;
}

.resty-cards-layout  > a:nth-child(100) {
  position: relative;
  left: 50%;
}

.app-he .resty-cards-layout  > a:nth-child(100) {
  transform: translateX(-200%);
}

.app-en .resty-cards-layout  > a:nth-child(100) {
  transform: translateX(100%);
}

.resty-card {
  color: #133A75;
}

.resty-card:hover{
  color: #59C2E4 !important;
}

.corona .resty-card-top {
  opacity: 0.8;
}

.resty-card-layout-1 .resty-card-top-img {
  width: 100%;
  height: 100%;
}
.resty-card-layout-2 .resty-card-top-img, .resty-card-layout-3 .resty-card-top-img {
  width: 100%;
  height: 100%;
}

.resty-card-top-img {
  height:100%;
  background-size:contain;
  border-radius: 6px;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
}

.resty-end-list {
  background-size: 100%;
  display: inline-block;
  background-image: url(/static/media/logo-mini.03604d10.png);
  content: "";
  position: relative;
}

.resty-end-list .resty-card {
  box-shadow: none !important;
}

/*TODO  - temp fix for image position - both here and in the image class*/
.resty-card-layout-1 .resty-card-top-img, .resty-card-layout-2 .resty-card-top-img, .resty-card-layout-3 .resty-card-top-img {
  left: 0;
}

.resty-card-ribbon {
  width: auto;
  height: 28px;
  left: 4px;
  top: 4px;
  
  border: 1px solid #F2F2F2;
  border-radius: 2px 14px 14px 14px;
  z-index: 30;
  position: absolute;
  font-size: 14px;
  color: #fff;
  line-height: 28px;
  padding: 0 10px;
}

.resty-card-reg .resty-card-ribbon, .resty-card-small .resty-card-ribbon {top: auto; bottom: 4px;}

.resty-card-ribbon-pop {
  background-color:#FF9737;
}

.resty-card-ribbon-new {
  background-color:#000000;
}

.resty-card-name {
  padding: 6px 32px;
  position: absolute;
  top: 0px;
  background: #FFFFFF;
  border-radius: 0px 0px 4px 4px;
  z-index: 20;
  left: 50%;
  transform: translate(-50%,0);
  white-space: nowrap;
  overflow: hidden;
  transition: color 0.5s; 
}

.resty-card-name-english-in-heb {
  font-family: 'Josefin Sans';
}

.resty-card-name-english {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  text-transform: capitalize;
}

.corona .resty-card-rank {
  color: #000 !important;
}

.resty-card-rank {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 20;
  display: block;

  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 16vw;

  line-height: 230px;
  height: 200px;

  color: #FFFFFF;

  text-shadow: 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 100px 80px rgba(0, 0, 0, 0.07);
}

.resty-card-small .resty-card-rank {
  font-weight: 300;
  font-size: 90px;

  line-height: 247px;
  height: 200px;
}

.resty-card-star { 
  width: 14px; 
  height: 14px;
  margin-bottom: -2px;
}

.resty-card-name-long {
  padding: 6px 15px;
}

/*
  Smartphone
*/
@media only screen and (max-width: 600px) {
  .resty-card {
  }

  .resty-card-big {height:328px!important; width:328px!important;}
  .resty-card-medium {height:160px; width:328px;}
  .resty-card-reg {height:160px; width:160px;}
  .resty-card-small {height:160px; width:160px}

  .resty-card-layout-1 {
    grid-column: 1 / 3 !important;
    grid-row: 1 / 3 !important;
  }

  .resty-card-layout-2 {
    grid-row: span 1 / span 1 !important;
    grid-column: span 2 / span 1 !important;
  }

  .resty-card-layout-3 {
    grid-row: span 1 / span 1 !important;
    grid-column: span 2 / span 1 !important;
  }

  .resty-card-layout-2:first-child, .resty-card-layout-3:first-child {
    grid-row: span 1 / span 1;
    grid-column: span 2 / span 1;
  }

  .resty-card-layout-3:nth-child(2) {
    grid-row: span 1 / span 1;
    grid-column: span 1 / span 1
  }
  
  .resty-card-reg {
    grid-row: span 1 / span 1;
    grid-column: span 1 / span 1;
  }

  .resty-card-small {
    grid-row: span 1 / span 1;
    grid-column: span 1 / span 1;
  }

  .resty-card-rank {
    font-size: 120px;
  }

  .app-he .resty-cards-layout > a:nth-child(100) {
    transform: translateX(-100%);
  }

  .app-en .resty-cards-layout > a:nth-child(100) {
    transform: translateX(0%);
  }

  .resty-card-name.resty-card-name-english {
    font-size: 14px;
    padding: 4px 15px;
  }

  .resty-card-name {
    padding: 6px 15px;
    max-width: 80%;
    font-size: 13px;
  }

  .resty-card-name.resty-card-name-english.resty-card-name-long {
    font-size: 11px !important;
  }
  
}

@media only screen and (min-width: 601px) and (max-width: 1000px) {
  .resty-card-small .resty-card-rank {
    font-size: 50px;
    line-height: 232px;
  }

  .resty-card-name.resty-card-name-english {
    font-size: 14px;
    padding: 4px 15px;
  }

  .resty-card-name {
    padding: 6px 15px;
    max-width: 80%;
    font-size: 13px;
  }

  .resty-card-name-long {
    font-size: 11px !important;
    padding: 8px 15px;
  }

  .resty-card-name.resty-card-name-english.resty-card-name-long {
    padding: 4px 11px;
  }
}