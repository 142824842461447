
.resty-rank-bar {
    width: 110px;
    height: 80px;
    position: relative;
    text-align: center;
}

.resty-rest-ranking-part {
    flex-direction: row;
    justify-content: center;
    display: flex;
    top: 50px;
    position: relative;

    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.resty-rest-ranking-section {
    font-size: 12px;
    height: 150px;
    align-self: flex-start;
    align-items: flex-start;
    flex-grow: 1;

    display: flex;
    flex-direction: row;
    justify-content: center;
}

.resty-rank-leaves {
    position: absolute;
    width: 80px;
    height: 56px;
    color:  #59C2E4;
    left: 15px;
    top: -8px;
}

.resty-rank-bar-disabled .resty-rank-leaves {
    filter: invert(75%) sepia(0%) saturate(0%) hue-rotate(205deg) brightness(145%) contrast(87%);
}
.resty-rank-bar-disabled .resty-rank-number,
.resty-rank-bar-disabled .resty-rank-text {
    color: #ADADAD;
}

.app-en .resty-rank-leaves {
    top: -11px;
}

.resty-rank-number {
    font-size: 32px;
    transition: 2.5s ease-out;
}

.resty-rest-ranking-section:nth-child(3) .resty-rank-leaves {
    filter: invert(62%) sepia(78%) saturate(532%) hue-rotate(333deg) brightness(101%) contrast(102%); /*https://codepen.io/sosuke/pen/Pjoqqp*/
}

.resty-rest-ranking-section:nth-child(3) .resty-rank-number, .resty-rest-ranking-section:nth-child(3) .resty-rank-text {
    color: #E95121;
}

.resty-rest-ranking-section .resty-rank-text {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
}

.app-en .resty-rest-ranking-section .resty-rank-text {
    margin-top: 20px;
}


@media only screen and (max-width: 600px) {
    .resty-rank-leaves {
        position: absolute;
        width: 52px;
        height: 36px;
        left: 28px;
        top: -8px;
    }

    .resty-rank-number {
        font-size: 16px;
    }

    .resty-rank-text {
        font-size: 16px;
    }

    .resty-rest-ranking-section:nth-child(1) {
        width: 30%;
    }
    .resty-rest-ranking-section:nth-child(2) {
        width: 40%;
    }
    .resty-rest-ranking-section:nth-child(3) {
        width: 30%;
    }
    .resty-rest-ranking-section:nth-child(4) {
        width: 50%;
    }
    .resty-rest-ranking-section:nth-child(5) {
        width: 50%;
    }
    .resty-rest-ranking-section:nth-child(2) .resty-rank-bar{
        bottom: 20px;
    }
    .resty-rest-ranking-section:nth-child(4) .resty-rank-bar{
        margin-left: -40px;
    }
    .resty-rest-ranking-section:nth-child(5) .resty-rank-bar{
        margin-right: -40px;
    }
    .resty-rest-ranking-section:nth-child(2) .resty-rank-leaves {
        filter: invert(62%) sepia(78%) saturate(532%) hue-rotate(333deg) brightness(101%) contrast(102%); /*https://codepen.io/sosuke/pen/Pjoqqp*/
        width: 80px;
        height: 56px;
        left: 15px;
        top: -9px;
    }
    .resty-rest-ranking-section:nth-child(2) .resty-rank-number {
        font-size: 32px;
    }
    .resty-rest-ranking-section:nth-child(3) .resty-rank-leaves {
        filter: none;
    }
    .resty-rest-ranking-section:nth-child(2) .resty-rank-number, .resty-rest-ranking-section:nth-child(2) .resty-rank-text {
        color: #E95121;
    }
    .resty-rest-ranking-section:nth-child(3) .resty-rank-number, .resty-rest-ranking-section:nth-child(3) .resty-rank-text {
        color: inherit;
    }
    /*
    .resty-rest-ranking-section {
        align-self: initial;
        align-items: initial;
        flex-grow: initial;
    
        display: block;
        flex-direction: initial;
        justify-content: initial;
    }*/
    .resty-rest-ranking-part {
        flex-wrap: wrap;
        top: 60px;
    }
    .resty-rest-ranking-section {
        height: 100px;
    }
}