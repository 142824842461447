/*
https://codepen.io/dudleystorey/pen/yqrhw
*/
.ReviewPage {
    font-family: 'Rubik', sans-serif;
}

.ReviewPage h1 {
    text-align: center;
    margin-bottom: 30px;
    color: #133A75;
}

.app-en .ReviewPage h1 {
    font-family: 'Josefin Sans';
}

.ReviewPage-list {
    column-width: 320px;
    column-gap: 15px;
    width: 90%;
    max-width: 1100px;
    margin: 20px auto;
    direction: rtl;
}

.ReviewPage-list .ReviewPage-review {
    background: #fefefe;
    /*border: 2px solid #fcfcfc;*/
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(34, 25, 25, 0.4);
    margin: 0 2px 15px;
    /*padding: 15px;*/
    /*padding-bottom: 10px;*/
    transition: opacity .4s ease-in-out;
    display: inline-block;
    column-break-inside: avoid;
}

.ReviewPage-list:hover .ReviewPage-review:not(:hover) {
    opacity: 0.7;
}

/*
.app-en {
    font-family: 'Josefin Sans';
  }
  
  .app-he {
    font-family: 'Rubik', sans-serif;
  }
  */

.ReviewPage-review-content {
    padding: 20px;
}

.ReviewPage-review-date {
    text-align: center;
}

.ReviewPage-review-header {
    font-size: 23px;
    margin: 0;
}

.ReviewPage-review-description {
    margin-top: 10px;
}

.ReviewPage-review-image-container {
    
}

.ReviewPage-review-image {
    width: 100%;
    display: block;
}

.ReviewPage-review-logo {
    max-height: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.ReviewPage-review-footer {
    line-height: 40px;
    background-color: #F2F2F2;
    text-align: center;
}

.ReviewPage-review-footer a {
    color: #ADADAD;
    text-decoration: none;
}

@media screen and (max-width: 750px) { 
    .ReviewPage-list { column-gap: 0px; }
    .ReviewPage-list .ReviewPage-review { width: 100%; }
}