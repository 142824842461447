.Restaurant-Page {
    font-size: calc(10px + 2vmin);
  
    padding: 30px;
    background-color: #ffffff;
    color: #133A75;

    min-height: calc(100vh - 60px);
}

.app-en .Restaurant-Page {
  direction: ltr;
}

.app-he .Restaurant-Page {
  direction: rtl;
}

.Restaurant-Page h1 {
  font-size: 76px;
  margin: 0;
  padding: 30px 0 10px 0;
  font-style: normal;
  font-weight: 300;
}

.resty-rest-name-english-in-heb {
  font-family: 'Josefin Sans';
}

.Restaurant-Page h1 > span {
  color: #59C2E4;
}

.Restaurant-Page h2 {
  color: #ADADAD;
  font-size: 16px;
  position: relative;
  margin: 0 0 30px 0;
}

.Restaurant-Page h1, .Restaurant-Page h2, .Restaurant-Page h3, .Restaurant-Page h4, .Restaurant-Page h5 {
  text-align: center;
}

.Restaurant-Page .resty-rest-rank-leaves {
    width: 70px;
    height: 55px;
    right: 50%;
    position: absolute;
    margin-right: -35px;
    bottom: -15px;
}

.Restaurant-Page .resty-map-wrapper {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.Restaurant-Page .resty-rest-hours {
  margin: 30px 30px 70px;
}

.resty-map-wrapper { 
  margin: 0 0 120px 0;
}

.Restaurant-Page .resty-rest-hours .resty-rest-hours-scroll {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content:stretch;

  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.app-en .resty-rest-hours {
  direction: ltr;
}

.resty-rest-hours-section {
  font-size: 16px;
  color:#133A75;
  white-space: pre-line;
  width: 100%;
  text-align: center;
  padding: 20px 3px;
}

.resty-rest-hours-section > div:not(:first-child) {
  padding-top:10px;
}

.resty-rest-hours-section-today {
  background-color: #F8F8F8;
  border-radius: 4px;
  box-shadow: 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802), 
              0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 
              0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 
              0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 
              0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 
              0px 80px 80px rgba(0, 0, 0, 0.07);
}

.resty-rest-reservation {
  width: 148px;
  height: 52px;
  background: #133A75;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  line-height: 52px;
  text-align: center;
  position: absolute;
  margin: 24px;
  top: 0;
  right: 0;
  -webkit-touch-callout: none; 
    -webkit-user-select: none; 
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
  cursor: pointer;
}

.app-he .resty-rest-reservation {
  left: 0;
}

.resty-category-label {
    font-size: calc(5px + 2vmin) !important;
    margin: 10px
}

.resty-rest-price-coin {
    width: 30px;
    height: 30px;
    padding: 0 5px
}

.resty-rest-categories {
  display: flex;
  justify-content: center;
}

.MuiChip-root.resty-category-label {
  background-color: #59C2E4;
  color: #ffffff;
  border-radius: 4px;
}

.resty-rest-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #F8F8F8;
  height: 100px;
}

.app-he .resty-rest-footer {
  direction: ltr;
}

.Restaurant-Page .resty-rest-desc {
  white-space: pre-line;
  margin: 30px;
  font-size: 16px;
  line-height: 25px;
}

@media only screen and (max-width: 600px) {
  .Restaurant-Page {
    padding: 0 !important;
  }
  .resty-rest-footer {
    height: 76px;
  }
  .resty-rest-reservation {
    margin: 13px 10px 0 0;
    line-height: 56px;
  }

  .app-he .resty-rest-reservation {
    margin: 13px 10px 0 0;
    line-height: 56px;
    left: 10px;
  }

  .app-en .Restaurant-Page .resty-rest-hours {
    /*padding-left: 150px;*/
  }
  
  .Restaurant-Page .resty-rest-hours {
    margin: 30px 0;
  }

  .resty-rest-hours-section {
    min-width: 120px;
  }

  .Restaurant-Page .resty-rest-hours .resty-rest-hours-scroll {
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
  }

  .app-en .Restaurant-Page .resty-rest-hours .resty-rest-hours-scroll > :first-child {
    padding-left: 520px;
  }

  .app-he .Restaurant-Page .resty-rest-hours .resty-rest-hours-scroll > :first-child {
    padding-right: 520px;
  }

  .resty-rest-hours-section-today {
    box-shadow: none !important;
  }

  .resty-map-wrapper { 
    padding: 0 30px 120px 30px;
    margin: 0 !important;
  }

  .resty-social-bar {
    position: relative;
    padding: 10px 30px 10px 30px;
    flex-wrap: wrap;
    margin-top: 0 !important;
  }

  .resty-social-bar-item {
    margin-left: 0 !important;
    margin: 25px !important;
  }

  .resty-rest-categories {
    flex-wrap: wrap;
  }

  .resty-rest-footer:empty {
    display: none;
  }


  .Restaurant-Page .resty-rest-desc {
    margin-top: 40px;
  }
}